import { bus } from '@brskl/core';
import { storage } from '@brskl/core';
import { TEndpoint } from '@brskl/core';

type ConstructorOptions = {
  handler: any;
  endpoint: TEndpoint;
  url: string;
};

class ChannelConnector {
  private loading = false;
  private instance: WebSocket;
  private channelKey: any;
  private messageHandler: any;
  private endpoint: any;
  private url: any;
  private ws_url: string;
  private channelHandler: any;

  constructor(channelHandler: ConstructorOptions) {
    const { handler, endpoint, url } = channelHandler;
    this.messageHandler = handler;
    this.channelHandler = channelHandler;
    this.endpoint = endpoint;
    this.url = url;
    this.ws_url = 'wss://ws-chat-service.briskly.dev';
    this.init();
  }

  init() {
    bus.on('core$auth/init', this.connect);
    bus.on('core$auth/refresh', this.connect);
    bus.on('core$auth/logout', this.disconnect);
  }

  getChannel() {
    return this.instance;
  }

  private connect = async () => {
    if (this.instance) return;
    if (this.loading) return;

    try {
      this.loading = true;

      const response = await this.endpoint.get({ path: this.url });

      if (response && (response.channel_key || response.channels_keys)) {
        this.channelKey = response.channel_key || response.channels_keys;
        this.instance = new WebSocket(this.ws_url);
        this.subscribe();
      }
    } catch {
    } finally {
      this.loading = false;
    }
  };

  private disconnect = () => {
    this.unsubscribe();
    this.instance = null;
  };

  private subscribe = () => {
    if (this.instance) {
      this.instance.addEventListener('open', this.onOpen);
      this.instance.addEventListener('error', this.onError);
      this.instance.addEventListener('message', this.messageHandler.bind(this));
    }
  };

  private unsubscribe = () => {
    if (this.instance) {
      this.instance.removeEventListener('open', this.onOpen);
      this.instance.removeEventListener('error', this.onError);
      this.instance.removeEventListener('message', this.messageHandler.bind(this));
    }
  };

  private onError = (error: any) => {
    console.error(error);
  };

  private onOpen = () => {
    this.instance.send(JSON.stringify({ auth: this.channelKey }));
    this.channelHandler.channel = this.getChannel();
  };
}

export { ChannelConnector };
