export default [
  {
    path: '/chat',
    component: () => import('@/apps/chat/views/Chat'),
    // children: [
    //   {
    //     path: '',
    //     name: 'chat$index',
    //     component: () => import('@/apps/support/views/Support/Support'),
    //     children: [
    //       {
    //         path: 'chat/:chat',
    //         name: 'support$customer-chat',
    //         component: () => import('@/apps/support/views/Support/Chat/Chat'),
    //       },
    //     ],
    //   },
    // ],
  },
  {
    path: '/chat/customer',
    component: () => import('@/apps/chat/views/Chat'),
    props: {chatType: 'customer'},
    children: [
      {
        path: 'chat/:chat',
        name: 'support$customer-chat',
        component: () => import('@/apps/chat/views/Chat'),
      }]
  },
  {
    path: '/chat/company',
    component: () => import('@/apps/chat/views/Chat'),
    props: {chatType: 'company'},
    children: [
      {
        path: 'chat/:chat',
        name: 'support$company-chat',
        component: () => import('@/apps/chat/views/Chat'),
      }]
  },
  {
    path: '/chat/employee',
    component: () => import('@/apps/chat/views/Chat'),
    props: {chatType: 'employee'},
    children: [
      {
        path: 'chat/:chat',
        name: 'support$employee-chat',
        component: () => import('@/apps/chat/views/Chat'),
      }]
  },
];
