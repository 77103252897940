import { api, bus } from '@brskl/core';
import axios from 'axios';

class SupportAPI {
  getAvailableFunnels = () => {
    return api.endpoints.current.get({ path: 'dashboard/chat/get-funnels' });
  };

  getUnassignedCount = () => {
    return api.endpoints.current.get({ path: 'dashboard/chat/get-unassigned-count' });
  };

  findChatsByQuery = (opts = {}) => {
    return api.endpoints.current.get({
      path: 'dashboard/chat/find-chat',
      params: opts,
    });
  }

  setChatStatus = async (opts = {}) => {
    const { id, status, is_notification } = opts;
    return api.endpoints.current.post({
      path: 'dashboard/chat/set-chat-status',
      params: { chat_id: id, status, is_notification },
    });
  };

  isSubscribedForChat = async (opts = {}) => {
    const { chat_id } = opts;

    const { is_subscriber } = await api.endpoints.current.get({
      path: 'dashboard/chat/message/is-subscriber',
      params: { chat_id },
    });

    return is_subscriber;
  };

  /**
   * type filters = {
   *   administrator_comment_id: number
   * }
   */

  getChatList = (opts = {}) => {
    const source = axios.CancelToken.source();

    function onGetChatListCancel() {
      source.cancel('getChatList: canceled');
      bus.off('cancelAllRequests', onGetChatListCancel);
    }

    bus.on('cancelAllRequests', onGetChatListCancel);

    const { page = 1, limit = 20, funnel, status, q, type, filters = {} } = opts;

    const payload = { page, limit, q, status, ...filters };

    if (type) payload.type = type;
    if (funnel && funnel !== '-1') payload.funnel = funnel;
    if (status < 0) delete payload.status;
    if (status === -2) payload.check_comment = 1;

    return api.endpoints.current
      .get({
        path: 'dashboard/chat/get-list',
        params: payload,
        options: { cancelToken: source.token },
      })
      .finally(() => bus.off('cancelAllRequests', onGetChatListCancel));
  };

  getChatById = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.current.get({ path: 'dashboard/chat/get-by-id', params: { id } });
  };

  getChatMessages = (opts = {}) => {
    const { chat_id, page = 1, limit = 20 } = opts;
    return api.endpoints.current.get({ path: 'dashboard/chat/message/get-list', params: { chat_id, page, limit } });
  };

  editMessage = (opts = {}) => {
    const { text, chat_id, chat_message_id } = opts;
    return api.endpoints.current.post({
      path: 'dashboard/chat/message/update',
      params: { chat_id, chat_message_id, text },
    });
  };

  sendMessage = (opts = {}) => {
    const { chat_id, text, prevent_tg } = opts;
    let params = {chat_id, text}

    if (prevent_tg) {
      params = {chat_id, text, prevent_tg}
    }

    return api.endpoints.current.post({ path: 'dashboard/chat/message/send', params });
  };

  markMessageAsRead = (opts = {}) => {
    const { chat_id, id } = opts;
    return api.endpoints.current.post({ path: 'dashboard/chat/message/set-as-read', params: { chat_id, id } });
  };

  getCompanyByEmployee = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.current.get({ path: 'dashboard/company-employee/get-by-id', params: { id } });
  };

  downloadFile = async (opts = {}) => {
    const { id } = opts;

    const { access_token } = api.tokens.getTokens();

    const url = await api.endpoints.current.get({
      path: 'dashboard/chat/message/file/download',
      params: { id, token: access_token },
      options: { linkOnly: true },
    });

    return url;
  };

  uploadFile = (opts = {}) => {
    const { chat_id, file, text = '' } = opts;

    return api.endpoints.current.post({
      path: 'dashboard/chat/message/file/upload',
      params: {
        chat_id,
        file,
        text,
      },
    });
  };

  // chat_id
  commentGetList = (opts = {}) => {
    return api.endpoints.current.get({ path: 'dashboard/chat/comment/get-list', params: opts });
  };

  // (administrator_id, parent_id, text, chat_id)
  commentSend = (opts = {}) => {
    return api.endpoints.current.post({ path: 'dashboard/chat/comment/send', params: opts });
  };

  // (text, is_checked, is_read, chat_id-обязательный)
  commentUpdate = (opts = {}) => {
    return api.endpoints.current.post({ path: 'dashboard/chat/comment/update', params: opts });
  };

  profileGetList = (opts = {}) => {
    return api.endpoints.current.get({ path: 'dashboard/profile/get-list', params: opts });
  };

  replyToMessage = (opts = {}) => {
    return api.endpoints.current.post({ path: 'dashboard/chat/message/reply-to-message', params: opts });
  };

  searchByName = (opts = {}) => {
    return api.endpoints.current.get({ path: 'dashboard/customer/get-list', params: opts });
  };

  searchByChatId = (opts = {}) => {
    return api.endpoints.current.get({ path: 'dashboard/chat/find-message-in-chat', params: opts });
  } 
}

export const supportAPI = new SupportAPI();
